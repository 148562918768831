<template>
  <v-theme-provider dark>
    <base-section
      id="social"
      class="accent text-center"
      space="56"
    >
      <base-icon class="mb-8">
      </base-icon>
      <base-section-heading
       color="transparent"
       title="Social Media"
      >
   </base-section-heading>
      <v-container>
        <v-slide-group>
          <template v-for="n in 7">
            <v-slide-item
              :key="n"
              class="align-self-center"
            >
              <base-img
                :src="require(`@/assets/gove/logo-${n}-${$vuetify.theme.isDark ? 'dark' : 'light'}.png`)"
                color="grey"
                contain
                height="40"
                width="128"
              />
            </v-slide-item>
            <v-responsive
              v-if="n < 7"
              :key="`divider-${n}`"
              class="text-center"
              height="56"
              width="48"
            >
              <v-divider vertical />
            </v-responsive>
          </template>
        </v-slide-group>
      </v-container>
    </base-section>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'SectionNews',

    provide: {
      heading: { align: 'center' },
    },
  }
</script>
